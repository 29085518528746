.demo-wrapper {
    max-width: 1280px;
    height: 50%;
    width: 100%;
    padding: 1.5rem;
    margin-top: 1rem;
}

.demo-heading {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.00rem;
    font-weight: 500;
    text-align: center;
}

.demo-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin: auto;
    padding: 2.5rem 0px;
}

.demo-description {
    text-align: right;
    font-size: 1.2rem;
    font-weight: 100;
    flex-basis: 50%;
    padding-left: 1.25rem;
}

/* .demo-description-left {
    text-align: left;
    font-size: 1.2rem;
    font-weight: 100;
    flex-basis: 50%;
    padding-left: 1.25rem;
} */

.demo-description p {
    margin-left: auto;
    max-width: 28rem;
}

.demo-video {
    /* flex-basis: min-content;
    justify-content: flex-end;
    right: 0px ; */
    flex-basis: 50%;
}

.demo-player {
    max-width: 30rem;
}



@media (max-width: 768px) {
    .demo-content {
        flex-direction: column-reverse;
        padding: 2.5rem 0px;
    }
    
    .demo-description {
        text-align: center;
        padding: 0px;
    }
    
    /* .demo-description-left {
        text-align: center;
        padding: 0px;
    } */

    /* .demo-description-left p {
        margin: auto;
    } */

    .demo-description p {
        margin: auto;
    }

    /* .demo-player {
        max-width: 20rem;
        max-height: 10rem;
    } */
}

@media (max-width: 568) {
    /* .demo-description-left p {
        max-width: 50%;
    } */
    .demo-description p {
        max-width: 50%;
    }
    .demo-player {
        max-width: 27rem;
    }
}

